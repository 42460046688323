import React, {useEffect, useState} from "react"
import {Container,Navbar,Nav} from 'react-bootstrap';
import { Link } from "gatsby"
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-input-range/lib/css/index.css'

import SEO from "../../components/seo"

import Header from  "../../components/Common/Header"; 
import NavLink from  "../../components/NavLink/Preval_Presentation"; 
import {config} from '../../components/Common/constant';

import DigitalDomination from '../../components/modules/digital_domination';

import { GET_ARTICLE } from "../../queries/common_use_query";

const PresentationPage = () => {

    const [ article_data, setArticleData ] = useState('');
    const { loading, error, data } = GET_ARTICLE('digital-domination-for-your-home');

    useEffect(()=>{
        setArticleData(data && data.articles[0]);
    }, [data]);

    return <>
        <SEO title={article_data && article_data.Meta_Title} description={article_data && article_data.Meta_Description} />
        <div className="pre-wrapper">

        <Header />

        <DigitalDomination presentation="preval" />

        <NavLink tab_name="Marketing" prevLink={config.preval_people}  nextLink={config.preval_valuation_day} />

        </div>
    </>
}

export default PresentationPage