import React, {useState, useEffect} from "react"
import {Container,Navbar,Nav} from 'react-bootstrap';
import { Link } from "gatsby"
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-input-range/lib/css/index.css'

import {config} from '../../components/Common/constant';

import HTMLReactParser from 'html-react-parser';
import { GET_ARTICLE } from "../../queries/common_use_query";

import { useContainerContext } from "../../store/ContainerContext";

import VideoModule from "./video-module"

import Title_Block from  "../modules/title_block"; 

import TimeTrackerComp from "./time_tracker";

const DigitalDomination = (props) => {

    const context = useContainerContext();
    const { property_data } = context.state;

    const [ article_data, setArticleData ] = useState('');
    const { loading, error, data } = GET_ARTICLE('digital-domination-for-your-home');

    useEffect(()=>{
        setArticleData(data && data.articles[0]);
    }, [data]);

    var strapi_url = process.env.GATSBY_STRAPI_SRC;

    //console.log("price_expectancy", property_data.price_expectancy);

    return <>
        <TimeTrackerComp TabName={'Marketing'} />

      <div className="section-wrapper">
          <Container>

            <Title_Block Title={article_data && article_data.Title} Type={props.Type} PreviousLink={''} NextLink={''} NextPage={props.NextPage} PrevPage={props.PrevPage} instruct={true} />

            <p className="fig-content mb_cnt font_18">{article_data && HTMLReactParser(article_data.Content)}</p>

            <div className="employee-wrapper digital_dominat">
                <div className="property-launch-video">
                    <div className="video-wrap rectangle_video">
                        { article_data && article_data.Modules && <VideoModule video_url={article_data.Modules.length > 0 && article_data.Modules[0].Upload_Video ? strapi_url+''+article_data.Modules[0].Upload_Video.url : article_data.Modules[0].Embed_Video_URL} width="100%" height="100%" preview_url={article_data.Modules[0].Upload_Image && strapi_url+''+article_data.Modules[0].Upload_Image.url} playing={false} />
                        }
                    </div>
                </div>
            </div>

          </Container>
      </div>
    </>
}

export default DigitalDomination